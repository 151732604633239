<template>
  <div class="home">
    <nav class="fh5co-nav" role="navigation">
      <div class="container">
        <div class="row">
          <div class="col-xs-12 text-center">
            <div id="fh5co-logo"><a href="" style="font-family: 'Oleo Script', Sans-serif;">Wedding Invitation<strong>.</strong></a></div>
          </div>
        </div>
        
      </div>
    </nav>

    <!-- ======= Header Section ======= -->
    <Header :client='client' :config='config'/>

    <!-- ======= Couple Section ======= -->
    <Couple :client='client' :config='config'/>

    <!-- ======= Event Section ======= -->
    <Event :client='client' :days='days' :hours='hours' :minutes='minutes' :seconds='seconds' :config="config"/>

    <!-- ======= Maps Location Section ======= -->
    <div v-if="config.maps_section !== 0" id="fh5co-couple-story">
      <div class="container">
        <div class="row">
          <div class="col-md-8 col-md-offset-2 text-center fh5co-heading animate-box">
            <h2 style="font-family: 'Oleo Script', Sans-serif;" >{{ config.language === 1 ? 'Maps' : 'Peta Lokasi' }}</h2>
            
          </div>
        </div>
        <div class="row">
          <div class="col-md-12 col-md-offset-0 animate-box text-center">
            <!-- <div class="col-12" style="border-radius: 10px; border-style: solid; border-width: 10px; border-color: #e79552; background-color: #eee; width: 100%; height: 250px" id="map"></div>
             -->
             <iframe class="ftco-animate" :src="client.embed" width="95%" height="450" style="border-radius: 20px !important; border-style: solid; border-width: 2px; border-color:#e79552; margin: auto; margin-top: 10px;" allowfullscreen="" loading="lazy" referrerpolicy="no-referrer-when-downgrade"></iframe>
          </div>                
        </div>
          <div class="col-md-12 col-md-offset-0 text-center">
              <div class="display-t">
                  <div class="display-tc animate-box" data-animate-effect="fadeIn">
                      <a :href="client.url_maps" target="_blank" class="btn btn-default btn-sm"><i class='fas fa-map-marker-alt' style='font-size:15px'></i>{{ config.language === 1 ? 'Open Map' : 'Buka Map' }}</a>
                  </div>
              </div>
          </div>
      </div>
    </div>

    <div v-if="config.story_section !== 0" id="fh5co-gallery">
      <div class="container">
        <div class="row">
          <div class="col-md-8 col-md-offset-2 text-center fh5co-heading animate-box">
            <span>We Love Each Other</span>
            <h2 style="font-family: 'Oleo Script', Sans-serif;" >{{ config.language === 1 ? 'Our Story' : 'Kisah Kami' }}</h2>
          </div>
        </div>
        <div class="row">
          <div class="col-md-12 col-md-offset-0">
            <ul class="timeline animate-box">
              <li v-if="story.type1 !== undefined" class="media animate-box">
                <div class="timeline-badge" style="background-image:url(images/couple-1.jpg);"></div>
                <div class="timeline-media" v-if="story.media1.media !== null">
                  <img v-if="story.type1.type == 'foto'" :src="'https://admin.itsyourdayofficial.com/story/' + story.media1.media" width="100%" alt="">
                  <video v-if="story.type1.type == 'video'" width="100%" height="280px;" :src="'https://admin.itsyourdayofficial.com/story/' + story.media1.media +'#t=0.001'" type="video/mp4" controls preload="metadata"></video>
                </div>
                <div class="timeline-panel">
                  <div class="timeline-heading">
                    <h3 class="timeline-title">{{story.title1.title}}</h3>
                    <span class="date">{{story.month1.rangeTime}}</span>
                  </div>
                  <div class="timeline-body">
                    <p v-html="story.description1.description"></p>
                  </div>
                </div>
                
              </li>
              <li v-if="story.type2 !== undefined" class="timeline-inverted animate-box">
                <div class="timeline-badge" style="background-image:url(images/couple-2.jpg);"></div>
                <div class="timeline-media" v-if="story.media2.media !== null">
                  <img v-if="story.type2.type == 'foto'" :src="'https://admin.itsyourdayofficial.com/story/' + story.media2.media" width="100%" alt="">
                  <video v-if="story.type2.type == 'video'" width="100%" height="280px;" :src="'https://admin.itsyourdayofficial.com/story/' + story.media2.media +'#t=0.001'" type="video/mp4" controls preload="metadata"></video>
                </div>
                <div class="timeline-panel">
                  <div class="timeline-heading">
                    <h3 class="timeline-title">{{story.title2.title}}</h3>
                    <span class="date">{{story.month2.rangeTime}}</span>
                  </div>
                  <div class="timeline-body">
                    <p v-html="story.description2.description"></p>
                  </div>
                </div>
              </li>
              <li v-if="story.type3 !== undefined" class="media animate-box">
                <div class="timeline-badge" style="background-image:url(images/couple-3.jpg);"></div>
                <div class="timeline-media" v-if="story.media3.media !== null">
                  <img v-if="story.type3.type == 'foto'" :src="'https://admin.itsyourdayofficial.com/story/' + story.media3.media" width="100%" alt="">
                  <video v-if="story.type3.type == 'video'" width="100%" height="280px;" :src="'https://admin.itsyourdayofficial.com/story/' + story.media3.media +'#t=0.001'" type="video/mp4" controls preload="metadata"></video>
                </div>
                <div class="timeline-panel">
                  <div class="timeline-heading">
                    <h3 class="timeline-title">{{story.title3.title}}</h3>
                    <span class="date">{{story.month3.rangeTime}}</span>
                  </div>
                  <div class="timeline-body">
                    <p v-html="story.description3.description"></p>
                  </div>
                </div>
              </li>
              <li v-if="story.type4 !== undefined" class="timeline-inverted animate-box">
                <div class="timeline-badge" style="background-image:url(images/couple-2.jpg);"></div>
                <div class="timeline-media" v-if="story.media4.media !== null">
                  <img v-if="story.type4.type == 'foto'" :src="'https://admin.itsyourdayofficial.com/story/' + story.media4.media" width="100%" alt="">
                  <video v-if="story.type4.type == 'video'" width="100%" height="280px;" :src="'https://admin.itsyourdayofficial.com/story/' + story.media4.media +'#t=0.001'" type="video/mp4" controls preload="metadata"></video>
                </div>
                <div class="timeline-panel">
                  <div class="timeline-heading">
                    <h3 class="timeline-title">{{story.title4.title}}</h3>
                    <span class="date">{{story.month4.rangeTime}}</span>
                  </div>
                  <div class="timeline-body">
                    <p v-html="story.description4.description"></p>
                  </div>
                </div>
              </li>
              <li v-if="story.type5 !== undefined" class="media animate-box">
                <div class="timeline-badge" style="background-image:url(images/couple-3.jpg);"></div>
                <div class="timeline-media" v-if="story.media5.media !== null">
                  <img v-if="story.type5.type == 'foto'" :src="'https://admin.itsyourdayofficial.com/story/' + story.media5.media" width="100%" alt="">
                  <video v-if="story.type5.type == 'video'" width="100%" height="280px;" :src="'https://admin.itsyourdayofficial.com/story/' + story.media5.media +'#t=0.001'" type="video/mp4" controls preload="metadata"></video>
                </div>
                <div class="timeline-panel">
                  <div class="timeline-heading">
                    <h3 class="timeline-title">{{story.title5.title}}</h3>
                    <span class="date">{{story.month5.rangeTime}} </span>
                  </div>
                  <div class="timeline-body">
                    <p v-html="story.description5.description"></p>
                  </div>
                </div>
              </li>             
            </ul>
          </div>
        </div>
      </div>
    </div>
    
    <!-- ======= Gallery Section ======= -->
    <Galerry  v-if="config.gallery_section !== 0" :client='client' :gallery='gallery' :config="config"/>
    <Confirmation v-if="config.rsvp_by_wa_section !== 0" :client='client' :config="config"/>
    <Quotes :client='client'/>
    <AlsoInvitation v-if="config.invitation_section !== 0" :alsoInvite='alsoInvite' :config="config"/>
    <Angpao v-if="config.gift_section !== 0" :client='client' :config="config"/>

    <div id="fh5co-started" class="fh5co-bg" style="background-image:url(../assets/images/image2.jpg);">
      <div class="overlay"></div>
      <div class="container">
        <div class="row animate-box">
          <div class="col-md-8 col-md-offset-2 text-center fh5co-heading">
            <h2 style="font-family: 'Oleo Script', Sans-serif;">{{ config.language === 1 ? "Wedding Wish" : 'Ucapan Pernikahan' }}</h2>
            <p>{{ config.language === 1 ? "Send Prayers & Greetings to the Bride and Groom" : 'Kirimkan Doa & Ucapan Kepada kedua Mempelai' }}</p>
          </div>
        </div>
        <div class="row animate-box">
          <div class="col-md-10 col-md-offset-1">
            <form class="form-inline">
              <div class="col-md-8 col-md-offset-2">
                <div class="form-group">
                  <!-- <label for="name" class="sr-only">Nama</label> -->
                  <input type="name" v-model="submit.client_id" hidden>
                </div>
                <div class="form-group">
                  <label for="name" class="sr-only">{{ config.language === 1 ? "Name" : 'Nama' }}</label>
                  <input type="name" v-model="submit.name" class="form-control" id="name" :placeholder="config.language === 1 ? 'Name' : 'Nama'" autocomplete="off" required>
                </div>
                <span v-if="errors.name" style="margin-bottom:10px" class="badge badge-danger">
                  {{ config.language === 1 ? "Please fill in your name" : 'Harap isi nama anda' }}
                </span>
                <div class="form-group">
                  <!-- <input type="name" v-model="submit.confirmation" class="form-control" id="name" placeholder="Masukkan Nama Anda" autocomplete="off" required> -->
                  <select v-model="submit.confirmation" class="form-control" >
                    <option value="" disabled selected>
                      {{ config.language === 1 ? "Confirmation of attendance" : 'Konfirmasi Kehadiran' }}
                    </option>
                    <option value="hadir">{{ config.language === 1 ? "Attend" : 'Hadir' }}</option>
                    <option value="mungkin">{{ config.language === 1 ? "Maybe Attend" : 'Mungkin Hadir' }}</option>
                    <option value="tidak">{{ config.language === 1 ? "Not Attend" : 'Tidak Hadir' }}</option>
                  </select>
                </div>
                <span v-if="errors.confirmation" style="margin-bottom:10px" class="badge badge-danger">
                  {{ config.language === 1 ? "Please fill your attendance confirmation" : 'Harap isi konfirmasi kehadiran anda' }}
                </span>
                <div class="form-group">
                  <label for="name" class="sr-only">Pesan</label>
                  <textarea type="name" v-model="submit.comment" class="form-control" id="pesan" :placeholder="config.language === 1 ? 'Message' : 'Pesan'" autocomplete="off" required cols="50" rows="10"></textarea>
                </div>
                <!-- <span v-if="errors.comment" style="margin-bottom:10px" class="badge badge-danger">
                  Harap isi pesan anda
                </span> -->
              </div>
              <div class="col-md-4 col-md-offset-4">
                <button type="submit" class="btn btn-default btn-block" @click.prevent="post" >{{ config.language === 1 ? "Send Hopes" : 'Kirim Ucapan' }}</button>
              </div>
            </form>
          </div>
        </div>
        <div class="row animate-box">
        <div class="col-md-10 col-md-offset-1 widget" style="margin-top:10px;">
          <div class="col-md-8 col-md-offset-2">
            <ul class="list-group">
              <li v-for="h in hopes" :key="h.id" class="list-group-item">
                <div class="row">
                  <div class="col-xs-3 col-md-2">
                    <img src="assets/images/wedding-rings.png" class="img-responsive" style="border:2px solid #f0ad4e; border-radius:50%;" alt="" />
                  </div>
                  <div class="col-xs-9 col-md-10 alert alert-warning">
                    <div >
                      <label style="font-size:18px;" for="one">{{h.name}}</label>
                    </div>
                    <div>
                        <p v-if="h.confirmation == 'hadir'" style="font-size:12px;font-style: oblique;">{{ config.language === 1 ? "Attend" : 'Hadir' }}</p>
                        <p v-if="h.confirmation == 'mungkin'" style="font-size:12px;font-style: oblique;">{{ config.language === 1 ? "Maybe Attend" : 'Mungkin Hadir' }}</p>
                        <p v-if="h.confirmation == 'tidak'" style="font-size:12px;font-style: oblique;">{{ config.language === 1 ? "Not Attend" : 'Tidak Hadir' }}</p>
                      </div>
                    <div class="comment-text">
                      <p style="font-size:16px;"> "{{h.comment}}"</p>
                    </div>
                  </div>
                </div>
              </li>
            </ul>
            
            
          </div>
        </div>
      </div>
      </div>
    </div>
    
    
    <!-- <Protocol :config="config"/> -->
    
    <div id="fh5co-couple-story">
      <div class="container">
        <div class="row">
          <div class="display-t">
            <div class="display-tc">
              <div class="col-md-10 col-md-offset-1">
                <div style="z-index: 1000;" class="col-md-8 col-md-offset-2 text-center fh5co-heading animate-box">
                    <img style="width:auto; margin-bottom:-90px;" src="/assets/images/Asset-6-300x135.png" alt="">
                </div>
                <div class="col-md-6 col-sm-6 col-md-offset-3 text-center">
                  <div class="event-wrap animate-box">
                   <div class="card text-center">
                    <div class="card-body">
                      <p class="card-text" style="margin-top:100px; color: #000000;">
                        {{ config.language === 1 ? "It is an honor and happiness for us as a family if you are willing to be present to give your blessing to the bride and groom. We thank you for your presence and blessings." : 'Merupakan suatu kehormatan dan kebahagiaan bagi kami sekeluarga apabila Bapak/Ibu/Saudara/i berkenan hadir untuk memberikan doa restu kepada kedua mempelai. Atas kehadiran serta doa restu, kami ucapkan terima kasih.' }}
                      </p>
                      <h4>
                        {{ config.language === 1 ? "Happy from the our Big Family" : 'Turut berbahagia Segenap keluarga besar' }}
                      </h4>
                      <h1 v-if="client.hajat == 'wanita'">{{client.nama_wanita}}</h1>
                      <h1 v-if="client.hajat == 'pria'">{{client.nama_pria}}</h1>
                      <h1>&amp;</h1>
                      <h1 style="margin-bottom:80px;" v-if="client.hajat == 'wanita'">{{client.nama_pria}}</h1>
                      <h1 style="margin-bottom:80px;" v-if="client.hajat == 'pria'">{{client.nama_wanita}}</h1>
                      
                    </div>
                  </div>
                  </div>
                </div>
                <div class="col-md-8 col-md-offset-2 text-center fh5co-heading animate-box">
                    <img style="width:auto; margin-top:-100px;" src="/assets/images/Asset-7-300x135.png" alt="">
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <!-- FOOTER -->
    <footer class="fh5co-bg" style="background-image:url(../assets/images/image1.jpg);">
      <div class="container">

        <div class="row copyright">
          <div class="col-md-12 text-center">
            <p style="margin-top:10px;">
              <small style="font-family: 'Oleo Script', Sans-serif; color: #fff;">Developed by <a href="" style="color: #fff;">It's Your Day Teams</a></small>
            </p>
            <p>
              <ul class="fh5co-social-icons" style="margin-bottom:10px; margin-top:-10px;">
                <li><a href="https://www.instagram.com/itsyourday_digitalinvitation/"><i class="icon-instagram"></i></a></li>
              </ul>
            </p>
          </div>
        </div>

      </div>
	  </footer>
    
    
    
    <Modal
      v-show="isModalVisible"
      @close="closeModal"
      :client='client'
      :guest='guest'
      :config="config"
    />
    <audio :src="'https://admin.itsyourdayofficial.com/song/'+ audio" ref="audioElm" loop="loop"></audio>

    <div class="icon-bar">
      <button class="play" @click="play" v-if="playing"><i class="icon-pause"></i></button>
      <button class="play" @click="play" v-else><i class="icon-play"></i></button>
    </div>
  </div>
</template>

<script>
// const someSound = require("@/assets/audio/JanjiSuci.mp4"); 
// @ is an alias to /src
// import HelloWorld from '@/components/HelloWorld.vue'
import Header from '../components/Header.vue'
import Couple from '../components/Couple.vue'
import Event from '../components/Event.vue';
import Galerry from '../components/Galerry.vue';
import Modal from '../components/Modal.vue';
import Confirmation from '../components/Confirmation.vue';
import Quotes from '../components/Quotes.vue';
import Angpao from '../components/Angpao.vue';
import Protocol from '../components/Protocol.vue';
import AlsoInvitation from '../components/AlsoInvite.vue';
// import Location from '../components/MapsLocation.vue';
import moment from 'moment';
import axios from 'axios';
export default {
  name: 'Home',
  components: {
    // HelloWorld
    Header,
    Couple,
    Event,
    Galerry,
    Modal,
    Confirmation,
    Quotes,
    Angpao,
    Protocol,
    AlsoInvitation,
    // Location,
  },
  data() {
  return{
      client: {},
      guest: {},
      moment: moment,
      now: 0,
      count: 0,
      date: 'May 30, 2022',
      gallery: {},
      alsoInvite: {},
      config: {},
      story: {
        title1: '',
        description1: '',
        date1: '',
        month1: '',
        year1: '',
        media1: '',
        type1: '',
        order1: '',
        title2: '',
        description2: '',
        date2: '',
        month2: '',
        year2: '',
        media2: '',
        type2: '',
        order2: '',
        title3: '',
        description3: '',
        date3: '',
        month3: '',
        year3: '',
        media3: '',
        type3: '',
        order3: '',
        title4: '',
        description4: '',
        date4: '',
        month4: '',
        year4: '',
        media4: '',
        type4: '',
        order4: '',
        title5: '',
        description5: '',
        date5: '',
        month5: '',
        year5: '',
        media5: '',
        type5: '',
        order5: '',
      },
      form: {
          address: '',
          latitude : '',
          longitude : '',
          test: ''
      },
      submit : {
				client_id : '',
        name: '',
        comment: '',
        confirmation: ''
			},
      isModalVisible: true,
      playing: false,
      audio : '',
      errors : {},
      hopes: {}
    }
  },
  mounted(){
    
    // this.timer_loop();

    

    if (localStorage.getItem('reloaded')) {
      // The page was just reloaded. Clear the value from local storage
      // so that it will reload the next time this page is visited.
      localStorage.removeItem('reloaded');
    } else {
      // Set a flag so that we know not to reload the page twice.
      localStorage.setItem('reloaded', '1');
      location.reload();
    }

    let toParam = this.$route.query.to;
    if (toParam) {
      toParam = decodeURIComponent(toParam);
    if (toParam.includes('-')) {
      // use original parameter value
    } else {
      // use empty string as parameter value
      toParam = '';
    }
    } else {
      toParam = '';
    }
    this.$api.get('/' + this.$route.params.slug, {
      params: {
        to: toParam,
        template: window.location.origin + "/"
      }
    })
    .then(response => { 
      if (!response.data.data) {
        this.$router.push({ name: 'PageNotFound' });
        return;
      }
      var currentUrl = window.location.href;
      
      this.client = response.data.data;

      document.title = "Wedding Invitation " + response.data.data.nama_wanita + " & " + response.data.data.nama_pria + " by It'sYourDay";

      if(currentUrl.includes(this.client.template) == false){
        this.$router.push({ name: 'PageNotFound' });
      }

      this.guest = response.data.data;
      this.submit.client_id = this.client.id;
      this.date = moment(response.data.data.tgl_akad).format('MMMM DD, YYYY hh:mm:ss a');
      if (response.data.data.config[0].countdown == 1) {
        this.date = moment(response.data.data.tgl_resepsi).format('MMMM DD, YYYY hh:mm:ss a');
      }else{
        this.date = moment(response.data.data.tgl_akad).format('MMMM DD, YYYY hh:mm:ss a');
      }
      this.gallery = response.data.data.gallery;
      this.alsoInvite = response.data.data.also_invite;
      this.story.title1 = response.data.data.story[0];
      this.story.description1 = response.data.data.story[0];
      this.story.date1 = response.data.data.story[0];
      this.story.month1 = response.data.data.story[0];
      this.story.year1 = response.data.data.story[0];
      this.story.media1 = response.data.data.story[0];
      this.story.type1 = response.data.data.story[0];
      this.story.order1 = response.data.data.story[0];
      this.story.title2 = response.data.data.story[1];
      this.story.description2 = response.data.data.story[1];
      this.story.date2 = response.data.data.story[1];
      this.story.month2 = response.data.data.story[1];
      this.story.year2 = response.data.data.story[1];
      this.story.media2 = response.data.data.story[1];
      this.story.type2 = response.data.data.story[1];
      this.story.order2 = response.data.data.story[1];
      this.story.title3 = response.data.data.story[2];
      this.story.description3 = response.data.data.story[2];
      this.story.date3 = response.data.data.story[2];
      this.story.month3 = response.data.data.story[2];
      this.story.year3 = response.data.data.story[2];
      this.story.media3 = response.data.data.story[2];
      this.story.type3 = response.data.data.story[2];
      this.story.order3 = response.data.data.story[2];
      this.story.title4 = response.data.data.story[3];
      this.story.description4 = response.data.data.story[3];
      this.story.date4 = response.data.data.story[3];
      this.story.month4 = response.data.data.story[3];
      this.story.year4 = response.data.data.story[3];
      this.story.media4 = response.data.data.story[3];
      this.story.type4 = response.data.data.story[3];
      this.story.order4 = response.data.data.story[3];
      this.story.title5 = response.data.data.story[4];
      this.story.description5 = response.data.data.story[4];
      this.story.date5 = response.data.data.story[4];
      this.story.month5 = response.data.data.story[4];
      this.story.year5 = response.data.data.story[4];
      this.story.media5 = response.data.data.story[4];
      this.story.type5 = response.data.data.story[4];
      this.story.order5 = response.data.data.story[4];
      this.config = response.data.data.config[0];
      console.log(response.data.data.story[4]);
      this.audio = response.data.data.song;
      this.timer_loop();
      this.modifiedDate = Math.trunc(Date.parse(this.date) / 1000);
      this.$api.get('comment/' + this.client.id)
      .then(response => { 
        this.hopes = response.data.data;
      })
      // if(navigator.geolocation){
      //   navigator.geolocation.getCurrentPosition(
      //       position => {
      //           this.getAddress(
      //               response.data.data.latitude, 
      //               response.data.data.longitude
      //           );
      //           this.showUserLocationOnTheMap(
      //               response.data.data.latitude, 
      //               response.data.data.longitude
      //           );
      //       },
      //       error => {
      //           this.error = error.message;
      //       }
      //   )
      // }else{
      //   this.error = error.message;
      // }

      
    })
    .catch(error => {
      // this.$router.push({ name: 'PageNotFound' });
      console.log(error.response.data);
    });

    

  },
  created(){
    if(this.isModalVisible == true){
      document.body.style.overflow = "hidden"; 
      document.body.style.bottom = "0";
      document.body.style.left= "0";
      document.body.style.right= "0";
      document.body.style.top= "0";      
      document.body.style.position= "fixed";
    } 
  },
  methods:{
    timer_loop() {
      this.count++;
      this.now = Math.trunc(new Date().getTime() / 1000);
      if (this.count < 200 && this.modifiedDate > this.now) {
        setTimeout(this.timer_loop, 1000);
      } else {
        this.count = 0;
      }
    },

    play: function(event) {
      var a = this.$refs.audioElm;
      if (a.paused) {
        a.play();
        this.playing = true;
      } else {
        a.pause();
        this.playing = false;
      }
    },

    // showModal() {
    //   this.isModalVisible = true;
    // },
    closeModal() {
      this.isModalVisible = false;
      var a = this.$refs.audioElm;
      a.play();
      this.playing = true;

      if(this.isModalVisible == false){
        document.body.style.position= "static";
        document.body.style.overflow = "auto"; 
      }
    },
    // getAddress(lat, long){
    //   axios.get("https://api.tomtom.com/search/2/reverseGeocode/" 
    //   + lat 
    //   + ","
    //   + long
    //   + 
    //   ".json?key=a5J9h9LsWkBXLvh0dsaJkZRfx4fVPSFB")
    //   .then(response =>{
    //       this.form.address = response.data.addresses[0].address.freeformAddress;
    //   })
    //   .catch(error => {
    //       this.error = error.message;
    //   })
    // },
    // showUserLocationOnTheMap(latitude, longitude){
    //   // create map object
    //   let map = new google.maps.Map(document.getElementById("map"), {
    //       zoom: 15,
    //       center: new google.maps.LatLng(latitude, longitude),
    //       mapTypeId:google.maps.MapTypeId.ROADMAP
    //   });
    //   // add marker
    //   new google.maps.Marker({
    //       position: new google.maps.LatLng(latitude, longitude),
    //       map:map
    //   });
    // },
    post(){
      this.$api.post('comment', this.submit)
      .then(response =>{
          this.success = response.data.success
          this.submit.name = '';
          this.submit.comment = '';
          this.submit.confirmation = '';
          this.$api.get('comment/' + this.client.id)
          .then(response => { 
            this.hopes = response.data.data;
          })
          console.log(response)
      })
      .catch(error => {
          if (error.response.status === 401) {
            this.errors = error.response.data.errors;
          }
      });
    }
  },
  computed:{
    seconds() {
        const result = (this.modifiedDate - this.now) % 60;
        return result < 0 ? 0 : result;
    },
    minutes() {
        const result = Math.trunc((this.modifiedDate - this.now) / 60) % 60;
        return result < 0 ? 0 : result;
    },
    hours() {
        const result = Math.trunc((this.modifiedDate - this.now) / 60 / 60) % 24;
        return result < 0 ? 0 : result;
    },
    days() {
        const result = Math.trunc((this.modifiedDate - this.now) / 60 / 60 / 24);
        return result < 0 ? 0 : result;
    },
    modifiedDate : function(){
      return Math.trunc(Date.parse(this.date) / 1000)
    }
  },
  watch : {
    
  },
}
</script>
<style >

    #map {
        height: 100%;
    }

</style>