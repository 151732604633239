<template>
    <div id="fh5co-event" class="fh5co-bg" style="background-image:url(../assets/images/image1.jpg);">
      <div class="overlay"></div>
      <div class="container">
        <div class="row">
          <div class="col-md-8 col-md-offset-2 text-center fh5co-heading animate-box">
            <span>Our Special Events</span>
            <h2 style="font-family: 'Oleo Script', Sans-serif;">{{ config.language === 1 ? 'Place and Times Event' : 'Waktu dan Tempat Acara' }}</h2>
          </div>
          <div class="col-md-8 col-md-offset-2 text-center simply-countdown animate-box">
            <div class="simply-section simply-days-section">
              <span class="simply-amount">{{days}}</span>
              <span class="simply-word">
                {{ config.language === 1 ? 'Days' : 'Hari' }}
              </span>
            </div>
            <div class="simply-section simply-hours-section">
              <span class="simply-amount">{{hours}}</span>
              <span class="simply-word">
                {{ config.language === 1 ? 'Hours' : 'Jam' }}
              </span>
            </div>
            <div class="simply-section simply-minutes-section">
              <span class="simply-amount">{{minutes}}</span>
              <span class="simply-word">
                {{ config.language === 1 ? 'Minutes' : 'minutes' }}
              </span>
            </div>
            <div class="simply-section simply-minutes-section">
              <span class="simply-amount">{{seconds}}</span>
              <span class="simply-word">
                {{ config.language === 1 ? 'Seconds' : 'Detik' }}
              </span>
            </div>
          </div>
        </div>
        <div class="row">
          <div class="display-t">
            <div class="display-tc">
              <div class="col-md-10 col-md-offset-1">
                <div class="col-md-6 col-sm-6 text-center">
                  <div class="event-wrap animate-box">
                    <h3 style="font-weight: 900;">Akad</h3>
                    <h4 style="font-size:14px; margin-bottom:10px;"> {{ config.language === 1 ? 'Location' : 'Lokasi Acara' }}</h4>
                    <h5 style="font-weight: bold;">{{client.nama_venue}}</h5>
                    <div class="event-col">
                      <i class="icon-calendar"></i>
                      <span style="font-weight: bold;">{{ config.language === 1 ? moment(client.tgl_akad).locale("en").format("dddd, DD MMMM YYYY") : moment(client.tgl_akad).locale("id").format("dddd, DD MMMM YYYY") }}</span>
                    </div>
                    <div class="event-col">
                      <i class="icon-clock"></i>
                      <span v-if="client.tgl_akad_selesai == null" style="font-weight: bold;"> {{ config.language === 1 ? moment(client.tgl_akad).format("HH:mm") + ' - End' : moment(client.tgl_akad).format("HH:mm") + ' - Selesai' }}</span>
                      <span v-else style="font-weight: bold;">{{ moment(client.tgl_akad).format("HH:mm") }} - {{ moment(client.tgl_akad_selesai).format("HH:mm") }}</span>
                    </div>
                    <p style="font-weight: bold;" v-html="client.alamat_venue"></p>
                    <div v-if="client.url_maps_resepsi !== null" class="" data-animate-effect="fadeIn">
                      <a :href="client.url_maps" target="_blank" class="btn btn-default btn-sm" style="border:2px solid #e79552;color:#e79552;background: transparent;"><i class='fas fa-map-marker-alt' style='font-size:15px;color:#e79552;'></i> Buka Map</a>
                    </div>
                  </div>
                </div>
                <div class="col-md-6 col-sm-6 text-center">
                  <div class="event-wrap animate-box">
                    <h3 style="font-weight: 900;">{{ config.language === 1 ? 'Reception' : 'Resepsi' }}</h3>
                    <h4 style="font-size:14px; margin-bottom:10px;">{{ config.language === 1 ? 'Location' : 'Lokasi' }}</h4>
                    <h5 style="font-weight: bold;">{{client.nama_venue_resepsi}}</h5>
                    <div class="event-col">
                      <i class="icon-calendar"></i>
                      <span style="font-weight: bold;">{{ config.language === 1 ? moment(client.tgl_resepsi).locale("en").format("dddd, DD MMMM YYYY") : moment(client.tgl_resepsi).locale("id").format("dddd, DD MMMM YYYY") }}</span>
                    </div>
                    <div class="event-col">
                      <i class="icon-clock"></i>
                      <span v-if="client.tgl_resepsi_selesai == null" style="font-weight: bold;">{{ config.language === 1 ? moment(client.tgl_resepsi).format("HH:mm") + ' - End' : moment(client.tgl_resepsi).format("HH:mm") + ' - Selesai' }}</span>
                      <span v-else style="font-weight: bold;">{{ moment(client.tgl_resepsi).format("HH:mm") }} - {{ moment(client.tgl_resepsi_selesai).format("HH:mm") }}</span>
                    </div>
                    <p style="font-weight: bold;" v-html="client.alamat_venue"></p>
                    <div v-if="client.url_maps_resepsi !== null" class="" data-animate-effect="fadeIn">
                      <a :href="client.url_maps_resepsi" target="_blank" class="btn btn-default btn-sm" style="border:2px solid #e79552;color:#e79552;background: transparent;"><i class='fas fa-map-marker-alt' style='font-size:15px;color:#e79552;'></i> Buka Map</a>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
</template>
<script>
import moment from 'moment';
export default {
  props: ['client', 'days', 'hours', 'minutes', 'seconds', 'config'],
  data() {
    return{
        moment: moment,
    }
  },
}
</script>